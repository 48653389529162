import React, {FC, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import logo from '../../utils/images/logo.png';
import logoEn from '../../utils/images/logoEng.png';
import burgerMenu from '../../utils/images/burgerMenu.png';
import close from '../../utils/images/close.svg';
import './header.css';
import {Row, Select, Typography} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';

export const Header: FC = ({buttonClick, isOpenMenu}) => {
  const {t, i18n} = useTranslation();
  const {Text} = Typography;


  const changeLang = (value) => {
    i18n.changeLanguage(value);
  };

  const location = useLocation();
  const lng = location.pathname.split('/')[1];

  useEffect(() => {
    if (lng === 'ru' || lng === 'kk' || lng === 'en') {
      i18n.changeLanguage(lng);
    }
  }, [i18n, lng]);

  const activeTab = window.location.pathname;

  return (
    <Row className="header" justify="space-between" align="middle" style={{background: isOpenMenu && '#F8F8F8'}}>
      {window.innerWidth < 1015 ? (
        <>
          <Row>
            <Link to="/">
              <img
                className="logo-img"
                src={i18n.language === 'en' ? logoEn : logo}
                alt="logo"
                width={75}
                height={20}
              />
            </Link>
          </Row>
          <Row align="middle" className="font-euclid-flex p-18" style={{position: 'relative'}}>
            <img src={isOpenMenu ? close : burgerMenu} alt="burger-menu" onClick={buttonClick} width={40} height={20}/>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Link to="/">
              <img className="logo-img" src={i18n.language === 'en' ? logoEn : logo} alt="logo" width={150}
                   height={40}/>
            </Link>
          </Row>
          <Row align="middle" className="font-euclid-flex p-18">
            <div className="navButton">
              <Link to="/">
                <Text className={`nav-text font-euclid-flex p-18 dark-blue-text ${activeTab === '/' ? 'active' : ''}`}>
                  {t('mainPage')}
                </Text>
              </Link>
            </div>
            <div className="navButton">
              <Link to="/treatment-facilities">
                <Text
                  className={`nav-text font-euclid-flex p-18 dark-blue-text ${activeTab === '/treatment-facilities' ? 'active' : ''}`}>
                  {t('treatmentFacilities')}
                </Text>
              </Link>
            </div>
            <div className="navButton">
              <Link to="/projects">
                <Text
                  className={`nav-text font-euclid-flex p-18 dark-blue-text 
                  ${activeTab === '/projects' || activeTab === '/projects/shevchenko' || activeTab === '/projects/zhomart' || activeTab === '/projects/annenskaya' ? 'active' : ''}`}
                >
                  {t('ourProjects')}
                </Text>
              </Link>
            </div>
            <div className="navButton">
              <Link to="/management">
                <Text
                  className={`nav-text font-euclid-flex p-18 dark-blue-text ${activeTab === '/management' ? 'active' : ''}`}>
                  {t('management')}
                </Text>
              </Link>
            </div>
            <div className="navButton">
              <a className="nav-text dark-blue-text" style={{textDecoration: 'none'}} href="tel:+77019888711">
                +7 701 988 87 11
              </a>
            </div>
            <div>
              <Select
                suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                className="lang-selector"
                defaultValue={lng === 'ru' || lng === 'kk' || lng === 'en' ? lng : i18n.language}
                style={{width: 71}}
                onChange={changeLang}
                options={[
                  {value: 'ru', label: 'РУС'},
                  {value: 'en', label: 'ENG'},
                  {value: 'kk', label: 'ҚАЗ'},
                ]}
              />
            </div>
          </Row>
        </>
      )}
    </Row>
  );
}

export default Header;