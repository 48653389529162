import React, {useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Typography, Row, Image} from "antd";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import main from "../../utils/images/projects/zhomart/main.png";
import one from "../../utils/images/projects/zhomart/1.png";
import two from "../../utils/images/projects/zhomart/2.png";
import three from "../../utils/images/projects/zhomart/3.png";
import four from "../../utils/images/projects/zhomart/4.png";
import five from "../../utils/images/projects/zhomart/5.png";
import six from "../../utils/images/projects/zhomart/6.png";
import seven from "../../utils/images/projects/zhomart/7.jpeg";
import eight from "../../utils/images/projects/zhomart/8.png";
import './zhomart.css';
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";
import parse from "html-react-parser";

export default function Zhomart() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {t} = useTranslation();
  const {Text} = Typography;

  const row1 = [one, two, three];
  const row2 = [four, five, six];

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const isMobile = window.innerWidth < 1015;

  return (
    <div style={{position: "relative", width: "100vw", minHeight: "100vh"}}>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ? (
        <HeaderMobileMenu/>
      ) : (
        <>
          <div className="zhomart-page">
            <div className="texts">
              <Text className="navigation navigation-zhomart mb-3">
                <Link className="nav-text" to="/" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                  {t('mainPage')}
                </Link>/
                <Link className="nav-text" to="/projects" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                  {t('ourProjects')}
                </Link>
                /<b>{t('ourProjectsBlock.zhomart')}</b>
              </Text>
              <h1 className="title-32 mb-3 font-euclid-flex-medium">{t('ourProjectsBlock.zhomart')}</h1>
            </div>
            <img alt="zhomart" src={main} width={'100%'} height={isMobile ? 215 : 400}/>
            <div className="texts">
              {isMobile ? (
                <>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.zhomartDescMobile'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.zhomartDescMobile2'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.zhomartDescMobile3'))}
                  </p>
                </>
              ) : (
                <p className="desc-text">
                  {parse(t('ourProjectsBlock.zhomartDesc'))}
                </p>
              )}
              <h5 className="desc-text">
                <b>{isMobile ? t('ourProjectsBlock.zhomartJobMobile') : t('ourProjectsBlock.zhomartJob')}</b>
              </h5>
              {!isMobile && (
                <>
                  <Row className="projects-row" style={{marginBottom: '20px'}}>
                    {row1.map((item, index) => (
                      <div className="row-images" key={index}>
                        <Image
                          src={item}
                          alt={`zhomart + ${index}`}
                          height={237}
                          width={'100%'}
                          style={{borderRadius: '32px'}}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="projects-row" style={{marginBottom: '20px'}}>
                    {row2.map((item, index) => (
                      <div className="row-images" key={index}>
                        <Image
                          src={item}
                          alt={`zhomart + ${index}`}
                          height={237}
                          width={'100%'}
                          style={{borderRadius: '32px'}}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="projects-row">
                    <div className="row-images">
                      <Image
                        src={seven}
                        alt={`zhomartSeven`}
                        height={237}
                        width={'100%'}
                        style={{borderRadius: '32px'}}
                      />
                    </div>
                    <div className="row-images">
                      <Image
                        src={eight}
                        alt={`zhomart8`}
                        height={237}
                        width={'100%'}
                        style={{borderRadius: '32px'}}
                      />
                    </div>
                    <div className="row-images"/>
                  </Row>
                </>
              )}
            </div>
            {isMobile && (
              <>
                <Row className="projects-row" style={{marginBottom: '20px'}}>
                  {row1.map((item, index) => (
                    <div className="row-images" key={index}>
                      <Image
                        src={item}
                        alt={`zhomart + ${index}`}
                        height={215}
                        width={'100%'}
                      />
                    </div>
                  ))}
                </Row>
                <Row className="projects-row">
                  {row2.map((item, index) => (
                    <div className="row-images" key={index}>
                      <Image
                        src={item}
                        alt={`zhomart + ${index}`}
                        height={215}
                        width={'100%'}
                      />
                    </div>
                  ))}
                </Row>
                <Row className="projects-row">
                  <div className="row-images">
                    <Image
                      src={seven}
                      alt={`zhomartSeven`}
                      height={215}
                      width={'100%'}
                    />
                  </div>
                  <div className="row-images">
                    <Image
                      src={eight}
                      alt={`zhomart8`}
                      height={215}
                      width={'100%'}
                    />
                  </div>
                  <div className="row-images"/>
                </Row>
              </>
            )}
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}