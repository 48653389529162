import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {Typography, Button} from "antd";
import './homePage.css';
import OurResults from "../../components/OurResults/OurResults";
import downloadPdf from "../../utils/images/downloadPdf.png";
import WorkProcess from "../../components/WorkProcess/WorkProcess";
import Footer from "../../components/Footer/Footer";
import pdf from "../../utils/pdf/zakluchenie.pdf";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";

export default function HomePage() {
  const {t} = useTranslation();
  const {Text} = Typography;
  const navigate = useNavigate();
  const footerRef = useRef();

  const onButtonClick = () => {
    navigate('/treatment-facilities');
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);


  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const onScrollClick = () => {
    footerRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  const onDownloadPdf = () => {
    fetch(pdf).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'zakluchenie-ekspertizi.pdf';
        alink.click();
      })
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ?
        (
          <HeaderMobileMenu/>
        ) : (
          <>
            <div className="bg-image">
              <div className="main-page-container">
                <div className="main-page-content">
                  <h1 className="font-euclid-flex-medium main-page-title-text">{t('mainTitle')}</h1>
                  <p className="main-page-description-text font-gilroy">{t('mainDescriptionOne')}</p>
                  <p className="main-page-description-text font-gilroy">{t('mainDescriptionTwo')}</p>
                  <Button className="buttons" onClick={onButtonClick}>
                    <Text className="button-text">{t('more')}</Text>
                  </Button>
                  <Button className="buttons contact-us" onClick={onScrollClick}>
                    <Text className="button-text">{t('contactUs')}</Text>
                  </Button>
                </div>
              </div>
            </div>
            <div className="our-company font-gilroy">
              <h3 className="our-company-title">{t('ourCompany')}</h3>
              {window.innerWidth < 1015 ? (
                <>
                  <p className="p-18" style={{margin: ' 0 0 12px'}}>{t('descMobile1')}</p>
                  <p className="p-18" style={{margin: ' 0 0 12px'}}>{t('descMobile2')}</p>
                  <p className="p-18" style={{margin: ' 0 0 12px'}}>{t('descMobile3')}</p>
                </>
              ) : (
                <span className="p-20 mb-4">{t('ourCompanyDesc')}</span>
              )}
              <h4 className="small-title">{t('ourApproach')}</h4>
              <span className={`${window.innerWidth < 1015 ? 'p-18' : 'p-20'} mb-3`}>{t('ourApproachDesc')}</span>
              <h4 className="small-title">{t('ourMission')}</h4>
              <span className={`${window.innerWidth < 1015 ? 'p-18' : 'p-20'} mb-4`}>{t('ourMissionDesc')}</span>
            </div>
            <OurResults/>
            <div className="conclusion">
              <h3 className="conclusion-text">{t('conclusion')}</h3>
              <Button className="buttons download-pdf-button" onClick={onDownloadPdf}>
                <Text className="download-text font-euclid-flex white-text p-18">
                  {t('downloadPdf')}
                </Text>
                <img alt="download-pdf" src={downloadPdf} width={20.4} height={24} style={{marginLeft: '8px'}}/>
              </Button>
            </div>
            <WorkProcess/>
            <section ref={footerRef}>
              <Footer/>
            </section>
          </>
        )}
    </>
  );
}