import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";
import {Typography, Row} from "antd";
import ceo from "../../utils/images/management/ceo.png";
import ceoMobile from "../../utils/images/management/ceo-mobile.png";
import tech from "../../utils/images/management/tech.png";
import techMobile from "../../utils/images/management/tech-mobile.png";
import './management.css';
import {Link} from "react-router-dom";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";

export default function Management() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {t} = useTranslation();
  const {Text} = Typography;
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  return (
    <div style={{position: "relative", width: "100vw", minHeight: "100vh"}}>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ? (
        <HeaderMobileMenu/>
      ) : (
        <>
          <div className="management-page">
            <div className="manufacture-block">
              <div style={{margin: window.innerWidth < 1015 ? "0" : "0 80px"}}>
                <Text className="navigation mb-3">
                  <Link to="/" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                    {t('mainPage')}
                  </Link>
                  /<b>{t('management')}</b>
                </Text>
                <h1 className="management-title">{t('managementBlock.title')}</h1>
                {window.innerWidth < 1015 ? (
                  <>
                    <img className="photo" alt="ceo" src={ceoMobile}/>
                    <div style={{margin: '12px 24px'}}>
                      <Text
                        className="bold-text font-euclid-flex-medium"
                        style={{
                          fontSize: '24px',
                          fontWeight: '700px',
                          width: '100%',
                          color: '#0073B7',
                          marginBottom: '4px',
                        }}>
                        {t('managementBlock.ceoName')}
                      </Text>
                      <p className="p-18 bold-text font-gilroy"
                         style={{margin: '4px 0', width: '100%'}}>
                        {t('managementBlock.ceo')}
                      </p>
                    </div>
                    <img style={{display: 'none'}} className="photo" alt="tech" src={techMobile}/>
                    <div style={{margin: '12px 24px', display: 'none'}}>
                      <Text
                        className="bold-text font-euclid-flex-medium"
                        style={{
                          fontSize: '24px',
                          fontWeight: '700px',
                          width: '100%',
                          color: '#0073B7',
                          marginBottom: '4px',
                        }}>
                        {t('managementBlock.technicalDirectorName')}
                      </Text>
                      <p className="p-18 bold-text font-gilroy"
                         style={{margin: '4px 0', width: '100%'}}>
                        {t('managementBlock.technicalDirector')}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Row style={{marginBottom: '12px'}}>
                      <img className="photo" alt="ceo" src={ceo} style={{marginRight: '56px'}}/>
                      <img style={{display: 'none'}} className="photo" alt="tech" src={tech}/>
                    </Row>
                    <Row className="mb-1">
                      <Text
                        className="title-32 font-euclid-flex-medium"
                        style={{
                          marginRight: '56px',
                          width: '520px',
                          color: '#0073B7'
                        }}>
                        {t('managementBlock.ceoName')}
                      </Text>
                      <Text
                        className="title-32 font-euclid-flex-medium"
                        style={{
                          color: '#0073B7',
                          display: 'none'
                        }}>
                        {t('managementBlock.technicalDirectorName')}
                      </Text>
                    </Row>
                    <Row>
                      <Text className="title-24 font-gilroy"
                            style={{marginRight: '56px', width: '520px', display: 'none'}}>
                        {t('managementBlock.ceo')}
                      </Text>
                      <Text className="title-24 font-gilroy">{t('managementBlock.technicalDirector')}</Text>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}