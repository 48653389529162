import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import './treatmentFacilities.css';
import ManufactureBase from "../../components/ManufactureBase/ManufactureBase";
import TreatmentsBlock from "../../components/TreatmentsBlock/TreatmentsBlock";
import Footer from "../../components/Footer/Footer";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";

export default function TreatmentFacilities() {

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ? (
        <HeaderMobileMenu/>
      ) : (
        <>
          <ManufactureBase/>
          <TreatmentsBlock/>
          <Footer isWhite={true}/>
        </>
      )}
    </>
  );
}