import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Carousel, Typography} from "antd";
import parse from 'html-react-parser';

import slide1 from "../../utils/images/treats/render1.png";
import slide2 from "../../utils/images/treats/render2.png";
import slide3 from "../../utils/images/treats/render3.png";
import slide4 from "../../utils/images/treats/render4.png";
import leftArrow from "../../utils/images/leftArrow.png";
import rightArrow from "../../utils/images/rightArrow.png";


export default function TreatmentsBlock() {
  const {t} = useTranslation();
  const {Text} = Typography;

  const renderRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const onChange = (current) => {
    setCurrentSlide(current);
  };

  const slideList = [slide1, slide2, slide3, slide4];

  return (
    <div className="treatment-facilities-block">
      <div style={{padding: window.innerWidth < 1015 ? '32px 24px' : '24px 80px 0'}}>
        <h3
          className="title-32 mb-3 font-euclid-flex white-text"
          style={{marginTop: window.innerWidth < 1015 ? '0px' : ''}}
        >
          {t('treatmentFacilities')}
        </h3>
        {
          window.innerWidth < 1015 ? (
            <>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescMobile'))}</p>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescMobile1'))}</p>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescMobile2'))}</p>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescTwoMobile'))}</p>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescTwoMobile1'))}</p>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescTwoMobile2'))}</p>
            </>
          ) : (
            <>
              <Text className="treat-desc white-text">{parse(t('treatment.treatDesc'))}</Text>
              <p className="treat-desc white-text">{parse(t('treatment.treatDescTwo'))}</p>
            </>
          )
        }
      </div>
      <div/>
      <div className="our-manufacture">
        <Carousel
          afterChange={onChange}
          ref={ref => {
            renderRef.current = ref
          }}
          dots={false}
        >
          {slideList.map((item, index) => (
            <div key={index}>
              <img
                style={{margin: "0 auto", width: '100vw'}}
                src={item}
                alt={`slide + ${index}`}
                height={window.innerWidth < 1015 ? 236 : 800}
              />
            </div>
          ))}
        </Carousel>
        <div>
          <div className="left-arrow" onClick={() => renderRef.current.prev()}>
            <img className="left-arrow-icon" src={leftArrow} alt="left"/>
          </div>
          <div className="right-arrow" onClick={() => renderRef.current.next()}>
            <img className="right-arrow-icon" src={rightArrow} alt="right"/>
          </div>
        </div>
        <div className="slider-dots-buttons">
          <div
            className={`${currentSlide === 0 && 'active-dot'} dots-buttons-white`}
            onClick={() => renderRef.current.goTo(0)}
          ></div>
          <div
            className={`${currentSlide === 1 && 'active-dot'} dots-buttons-white`}
            onClick={() => renderRef.current.goTo(1)}
          ></div>
          <div
            className={`${currentSlide === 2 && 'active-dot'} dots-buttons-white`}
            onClick={() => renderRef.current.goTo(2)}
          ></div>
          <div
            className={`${currentSlide === 3 && 'active-dot'} dots-buttons-white last-dot`}
            onClick={() => renderRef.current.goTo(3)}
          ></div>
        </div>
        <p className="font-euclid-flex p-18 mb-3 white-text">{t('treatment.sliderTitle')}</p>
      </div>
    </div>
  );
}