import React from 'react';
import {useTranslation} from "react-i18next";


export default function WorkProcess() {
  const {t, i18n} = useTranslation();

  return (
    <div className="work-process">
      <div
        className="vertical-divider"
        style={{
          height:
            window.innerWidth < 1015 ?
              i18n.language === 'en' ? '960px' : '1070px' :
              i18n.language === 'kk' ? '630px' : '600px'
        }}></div>
      <h3 className="work-process-title">{t('workProcessBlock.title')}</h3>
      <span className="work-process-description">{t('workProcessBlock.description')}</span>
      <div className="steps">
        <div className="step-number" style={{textAlign: "center"}}>
          <p style={{margin: "3px auto 0", textAlign: "center"}}>1</p>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepOne')}</p>
          <p className="step-description">{t('workProcessBlock.stepOneDesc')}</p>
          <p className="step-description">{t('workProcessBlock.stepOneDescTwo')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>2</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepTwo')}</p>
          <p className="step-description">{t('workProcessBlock.descTwo')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>3</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepThree')}</p>
          <p className="step-description">{t('workProcessBlock.descThree')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>4</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepFour')}</p>
          <p className="step-description">{t('workProcessBlock.descFour')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>5</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepFive')}</p>
          <p className="step-description">{t('workProcessBlock.descFive')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>6</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepSix')}</p>
          <p className="step-description">{t('workProcessBlock.descSix')}</p>
        </div>
      </div>
      <div className="steps">
        <div className="step-number" style={{display: "flex", flexWrap: 'wrap'}}>
          <span style={{margin: "auto"}}>7</span>
        </div>
        <div className="step-info">
          <p className="step-title">{t('workProcessBlock.stepSeven')}</p>
          <p className="step-description">{t('workProcessBlock.descSeven')}</p>
          <p className="step-description">{t('workProcessBlock.descSevenTwo')}</p>
        </div>
      </div>
    </div>
  );
}