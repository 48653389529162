import './App.css';
import './fonts/style.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import HomePage from "./pages/HomePage/HomePage";
import Management from "./pages/Management/Management";
import Projects from "./pages/Projects/Projects";
import TreatmentFacilities from "./pages/TreatmentFacilities/TreatmentFacilities";
import Zhomart from "./pages/Zhomart/Zhomart";
import Annenskaya from "./pages/Annenskaya/Annenskaya";
import Shevchenko from "./pages/Shevchenko/Shevchenko";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/ru" element={<HomePage/>}/>
          <Route path="/en" element={<HomePage/>}/>
          <Route path="/kk" element={<HomePage/>}/>
          <Route path="/management" element={<Management/>}/>
          <Route path="/ru/management" element={<Management/>}/>
          <Route path="/en/management" element={<Management/>}/>
          <Route path="/kk/management" element={<Management/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/ru/projects" element={<Projects/>}/>
          <Route path="/en/projects" element={<Projects/>}/>
          <Route path="/kk/projects" element={<Projects/>}/>
          <Route path="/projects/zhomart" element={<Zhomart/>}/>
          <Route path="/ru/projects/zhomart" element={<Zhomart/>}/>
          <Route path="/en/projects/zhomart" element={<Zhomart/>}/>
          <Route path="/kk/projects/zhomart" element={<Zhomart/>}/>
          <Route path="/projects/annenskaya" element={<Annenskaya/>}/>
          <Route path="/ru/projects/annenskaya" element={<Annenskaya/>}/>
          <Route path="/en/projects/annenskaya" element={<Annenskaya/>}/>
          <Route path="/kk/projects/annenskaya" element={<Annenskaya/>}/>
          <Route path="/projects/shevchenko" element={<Shevchenko/>}/>
          <Route path="/ru/projects/shevchenko" element={<Shevchenko/>}/>
          <Route path="/en/projects/shevchenko" element={<Shevchenko/>}/>
          <Route path="/kk/projects/shevchenko" element={<Shevchenko/>}/>
          <Route path="/treatment-facilities" element={<TreatmentFacilities/>}/>
          <Route path="/ru/treatment-facilities" element={<TreatmentFacilities/>}/>
          <Route path="/en/treatment-facilities" element={<TreatmentFacilities/>}/>
          <Route path="/kk/treatment-facilities" element={<TreatmentFacilities/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
