import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Typography, Button, Carousel} from "antd";
import {useNavigate} from 'react-router-dom';
import slide1 from '../../utils/images/results-slider1.png';
import slide2 from '../../utils/images/results-slider2.png';
import slide3 from '../../utils/images/results-slider3.png';
import rightArrow from '../../utils/images/rightArrow.png';
import leftArrow from '../../utils/images/leftArrow.png';
import './ourResults.css';

export default function OurResults() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {Text} = Typography;
  const bannerRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const onChange = (current) => {
    setCurrentSlide(current);
  };

  const onButtonClick = () => {
    navigate('/projects')
  };

  const slides = [slide1, slide2, slide3];

  return (
    <>
      <h3 className="our-results-title">{t('results')}</h3>
      <div className="our-results">
        <Carousel
          afterChange={onChange}
          ref={ref => {
            bannerRef.current = ref
          }}
          dots={false}
        >
          {slides.map((item, index) => (
            <div key={index}>
              <img style={{margin: "0 auto", width: '100vw'}} src={item} alt={`slide + ${index}`}
                   height={window.innerWidth < 1015 ? 236 : 501}/>
            </div>
          ))}
        </Carousel>
        <div>
          <div className="left-arrow-results" onClick={() => bannerRef.current.prev()}>
            <img className="left-arrow-results-icon" src={leftArrow} alt="left"/>
          </div>
          <div className="right-arrow-results" onClick={() => bannerRef.current.next()}>
            <img className="right-arrow-results-icon" src={rightArrow} alt="right"/>
          </div>
          <p className="text-on-carousel text-before">{t('before')}</p>
          <p className="text-on-carousel text-after">{t('after')}</p>
        </div>
        <div className="slider-dots-buttons">
          <div
            className={`${currentSlide === 0 && 'active-dot'} dots-buttons`}
            onClick={() => bannerRef.current.goTo(0)}
          ></div>
          <div
            className={`${currentSlide === 1 && 'active-dot'} dots-buttons`}
            onClick={() => bannerRef.current.goTo(1)}
          ></div>
          <div
            className={`${currentSlide === 2 && 'active-dot'} dots-buttons last-dot`}
            onClick={() => bannerRef.current.goTo(2)}
          ></div>
        </div>

        <p className="font-euclid-flex p-18 mb-3">{t('taldykol')}</p>
        <Button className="buttons button-all-projects" onClick={onButtonClick}>
          <Text className="font-euclid-flex white-text p-18">{t('allProjects')}</Text>
        </Button>
      </div>
    </>
  );
}