import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import './footer.css';
import {Row, Col, Typography} from 'antd';
import downloadPdf from "../../utils/images/downloadPdf.png";
import download from "../../utils/images/download.svg";
import marker from "../../utils/images/marker.svg";
import mail from "../../utils/images/mail.svg";
import phone from "../../utils/images/phone.svg";
import footerLogo from "../../utils/images/footerLogo.svg";
import footerLogoLightTheme from "../../utils/images/footerLogoWhiteTheme.svg";
import logoEnLightTheme from "../../utils/images/logoEng.png";
import logoEn from "../../utils/images/footerLogoEngWhiteTheme.svg";
import pdf from "../../utils/pdf/avtorskoe.pdf";

export default function Footer(props) {
  const {t, i18n} = useTranslation();
  const {Text} = Typography;

  const activeTab = window.location.pathname;

  const onDownloadPdf = () => {
    fetch(pdf).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'avtorskoe-pravo.pdf';
        alink.click();
      })
    })
  }


  return (
    <div className="footer" style={{background: props.isWhite && "#FFFFFF"}}>
      {window.innerWidth < 1015 ?
        (
          <>
            <div className="mb-4">
              <Text className={`contacts bold-text ${props.isWhite && 'black-text'}`}>
                {t('footerBlock.contacts')}
              </Text>
            </div>
            <div className="mb-4">
              <a href="tel:+77019888711" style={{textDecoration: 'none'}}>
                <Text className={`contacts ${props.isWhite && 'black-text'}`}>
                  <img alt="phone" src={phone} width={24} height={24} style={{marginRight: '8px'}}/>
                  {t('footerBlock.tel')}
                </Text>
              </a>
            </div>
            <div className="mb-4">
              <a href="mailto:water@biostart.kz" style={{textDecoration: 'none'}}>
                <Text className={`contacts ${props.isWhite && 'black-text'}`}>
                  <img alt="mail" src={mail} width={24} height={19.2} style={{marginRight: '8px'}}/>
                  {t('footerBlock.email')}
                </Text>
              </a>
            </div>
            <div className="mb-2">
              <Text className={`address-title ${props.isWhite && 'black-text'}`}>
                <img alt="marker" src={marker} width={14} height={18} style={{marginRight: '8px'}}/>
                {t('footerBlock.centralOffice')}
              </Text>
            </div>
            <div style={{maxWidth: '339px', margin: '0 auto 24px'}}>
              <Text className={`address-description ${props.isWhite && 'black-text'}`}>
                {t('footerBlock.centralAddress')}
              </Text>
            </div>
            <div className="mb-2">
              <Text className={`address-title ${props.isWhite && 'black-text'}`}>
                <img alt="marker" src={marker} width={14} height={18} style={{marginRight: '8px'}}/>
                {t('footerBlock.frontOffice')}
              </Text>
            </div>
            <div style={{maxWidth: '339px', margin: '0 auto 40px'}}>
              <Text className={`address-description ${props.isWhite && 'black-text'}`}>
                {t('footerBlock.frontAddress')}
              </Text>
            </div>
            <div onClick={onDownloadPdf} style={{cursor: "pointer"}}>
              <Text
                className={`footer-nav ${activeTab === '/management' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                {t('footerBlock.authorPdf')}
                <img
                  className={'nav-text'}
                  src={props.isWhite ? download : downloadPdf}
                  alt="download-pdf"
                  width={13.6}
                  height={16}
                  style={{marginLeft: '8px'}}
                />
              </Text>
            </div>
          </>
        ) : (
          <>
            <Row justify="space-between">
              <Col span={8}>
                <div className="mb-4">
                  <Link to="/">
                    <Text
                      className={`nav-text footer-nav ${activeTab === '/' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                      {t('mainPage')}
                    </Text>
                  </Link>
                </div>
                <div className="mb-4">
                  <Link to="/treatment-facilities">
                    <Text
                      className={`nav-text footer-nav ${activeTab === '/treatment-facilities' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                      {t('treatmentFacilities')}
                    </Text>
                  </Link>
                </div>
                <div className="mb-4">
                  <Link to="/projects">
                    <Text
                      className={`nav-text footer-nav ${activeTab === '/projects' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                      {t('ourProjects')}
                    </Text>
                  </Link>
                </div>
                <div className="mb-4">
                  <Link to="/management">
                    <Text
                      className={`nav-text footer-nav ${activeTab === '/management' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                      {t('management')}
                    </Text>
                  </Link>
                </div>
                <div onClick={onDownloadPdf} style={{cursor: "pointer"}}>
                  <Text
                    className={`footer-icon footer-nav ${activeTab === '/management' ? 'active' : ''} ${props.isWhite && 'black-text'}`}>
                    {t('footerBlock.authorPdf')}
                    <img
                      className={'nav-text '}
                      src={props.isWhite ? download : downloadPdf}
                      alt="download-pdf"
                      width={13.6} height={16}
                      style={{marginLeft: '8px'}}
                    />
                  </Text>
                </div>
              </Col>
              <Col span={8} style={{textAlign: 'center'}}>
                <div className="mb-2">
                  <Text className={`address-title ${props.isWhite && 'black-text'}`}>
                    <img alt="marker" src={marker} width={14} height={18} style={{marginRight: '8px'}}/>
                    {t('footerBlock.centralOffice')}
                  </Text>
                </div>
                <div style={{maxWidth: '339px', margin: '0 auto 24px'}}>
                  <Text className={`address-description ${props.isWhite && 'black-text'}`}>
                    {t('footerBlock.centralAddress')}
                  </Text>
                </div>
                <div className="mb-2">
                  <Text className={`address-title ${props.isWhite && 'black-text'}`}>
                    <img alt="marker" src={marker} width={14} height={18} style={{marginRight: '8px'}}/>
                    {t('footerBlock.frontOffice')}
                  </Text>
                </div>
                <div style={{maxWidth: '339px', margin: '0 auto'}}>
                  <Text className={`address-description ${props.isWhite && 'black-text'}`}>
                    {t('footerBlock.frontAddress')}
                  </Text>
                </div>
              </Col>
              <Col span={8} style={{textAlign: 'right'}}>
                <div className="mb-4">
                  <Text className={`contacts bold-text ${props.isWhite && 'black-text'}`}>
                    {t('footerBlock.contacts')}
                  </Text>
                </div>
                <div className="footer-icon mb-4">
                  <a href="tel:+77019888711">
                    <Text className={`contacts ${props.isWhite && 'black-text'}`}>
                      {t('footerBlock.tel')}
                      <img alt="phone" src={phone} width={24} height={24} style={{marginLeft: '8px'}}/>
                    </Text>
                  </a>
                </div>
                <div className="footer-icon mb-4">
                  <a href="mailto:water@biostart.kz">
                    <Text className={`contacts ${props.isWhite && 'black-text'}`}>
                      {t('footerBlock.email')}
                      <img alt="mail" src={mail} width={24} height={19.2} style={{marginLeft: '8px'}}/>
                    </Text>
                  </a>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <img alt="logo-white" src={
                props.isWhite ?
                  i18n.language === 'en' ? logoEnLightTheme : footerLogoLightTheme
                  : i18n.language === 'en' ? logoEn : footerLogo} width={137} height={36}/>
            </Row>
          </>
        )}
    </div>
  );
}