import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import './header.css';
import {Typography} from 'antd';
import phone from "../../utils/images/phone.svg";
import mail from "../../utils/images/mail.svg";

export const HeaderMobileMenu: FC = () => {
  const {t, i18n} = useTranslation();
  const {Text} = Typography;

  const activeTab = window.location.pathname;

  return (
    <div className="mobile-nav">
      <div className="mb-4">
        <Link to="/" style={{textDecoration: 'none'}}>
          <Text
            className={`mobile-nav-links ${activeTab === '/' ? 'active' : ''}`}>
            {t('mainPage')}
          </Text>
        </Link>
      </div>
      <div className="mb-4">
        <Link to="/treatment-facilities" style={{textDecoration: 'none'}}>
          <Text
            className={`mobile-nav-links ${activeTab === '/treatment-facilities' ? 'active' : ''}`}>
            {t('treatmentFacilities')}
          </Text>
        </Link>
      </div>
      <div className="mb-4">
        <Link to="/projects" style={{textDecoration: 'none'}}>
          <Text
            className={`mobile-nav-links ${activeTab === '/projects' || activeTab === '/projects/shevchenko' || activeTab === '/projects/zhomart' || activeTab === '/projects/annenskaya' ? 'active' : ''}`}>
            {t('ourProjects')}
          </Text>
        </Link>
      </div>
      <div className="mb-4">
        <Link to="/management" style={{textDecoration: 'none'}}>
          <Text
            className={`mobile-nav-links ${activeTab === '/management' ? 'active' : ''}`}>
            {t('management')}
          </Text>
        </Link>
      </div>
      <div style={{position: 'absolute', bottom: '62px'}}>
        <div className="mb-4">
          <Text className={`contacts black-text`}>
            <a className="nav-text dark-blue-text" style={{textDecoration: 'none'}} href="tel:+77019888711">
              <img alt="phone" src={phone} width={24} height={24} style={{marginRight: '8px'}}/>
              {t('footerBlock.tel')}
            </a>
          </Text>
        </div>
        <div className="mb-4">
          <a className="nav-text dark-blue-text" style={{textDecoration: 'none'}} href="mailto:water@biostart.kz">
            <Text className={`contacts black-text`}>
              <img alt="mail" src={mail} width={24} height={19.2} style={{marginRight: '8px'}}/>
              {t('footerBlock.email')}
            </Text>
          </a>
        </div>
        <div className="bottom-lang-buttons">
          <div
            className={`lang-button ${i18n.language === 'ru' && 'active-button'}`}
            style={{borderRadius: '32px 0px 0px 32px'}}
            onClick={() => {
              i18n.changeLanguage('ru')
            }}
          >
            РУС
          </div>
          <div
            className={`lang-button ${i18n.language === 'en' && 'active-button'}`}
            onClick={() => {
              i18n.changeLanguage('en')
            }}
          >
            ENG
          </div>
          <div
            className={`lang-button ${i18n.language === 'kk' && 'active-button'}`}
            style={{borderRadius: '0px 32px 32px 0px'}}
            onClick={() => {
              i18n.changeLanguage('kk')
            }}
          >
            ҚАЗ
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobileMenu;