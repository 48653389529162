import React, {useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Typography, Row} from "antd";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import zhomart from "../../utils/images/projects/zhomart.png";
import arrow from "../../utils/images/projects/arrow.svg";
import arrowWhite from "../../utils/images/projects/whiteArrow.svg";
import annenskaya from "../../utils/images/projects/annenskaya/new4.png";
import shevchenko from "../../utils/images/projects/shevchenko/2.png";
import './projects.css';
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";

export default function Management() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {t} = useTranslation();
  const {Text} = Typography;

  const [firstArrowImage, setFirstArrowImage] = useState(arrow);
  const [secondArrowImage, setSecondArrowImage] = useState(arrow);
  const [thirdArrowImage, setThirdArrowImage] = useState(arrow);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  return (
    <div style={{position: "relative", width: "100vw", minHeight: "100vh"}}>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ? (
        <HeaderMobileMenu/>
      ) : (
        <>
          <div className="project-page">
            <Text className="navigation navigation-projects mb-3">
              <Link className="nav-text" to="/" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                {t('mainPage')}
              </Link>
              /<b>{t('ourProjects')}</b>
            </Text>
            <h1 className="title-32 mb-3 font-euclid-flex">{t('ourProjects')}</h1>
            <Row className="projects-row">
              <Link to="/projects/zhomart"
                    style={{
                      width: 'calc((100vw - 200px) / 3)',
                    }}>
                <div
                  className="project-card"
                  onMouseOver={() => (setFirstArrowImage(arrowWhite))}
                  onMouseOut={() => (setFirstArrowImage(arrow))}
                >
                  <img className="project-image" alt="zhomart" src={zhomart} width={'100%'} height={255}/>
                  <p className="card-title">{t('ourProjectsBlock.zhomart')}</p>
                  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <p className="card-performance">{t('ourProjectsBlock.zhomartPerformance')}</p>
                    <img
                      className="project-image"
                      alt="arrow"
                      src={firstArrowImage}
                      width={40}
                      height={20}
                    />
                  </div>
                </div>
              </Link>
              <Link to="/projects/annenskaya" style={{width: 'calc((100vw - 200px) / 3)'}}>
                <div
                  className="project-card"
                  onMouseOver={() => (setSecondArrowImage(arrowWhite))}
                  onMouseOut={() => (setSecondArrowImage(arrow))}
                >
                  <img className="project-image" alt="annensk" src={annenskaya} width={'100%'} height={255}
                       style={{borderRadius: '36px'}}/>
                  <p className="card-title">{t('ourProjectsBlock.annensk')}</p>
                  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <p className="card-performance">{t('ourProjectsBlock.annenskPerformance')}</p>
                    <img className="project-image" alt="arrow" src={secondArrowImage} width={40} height={20}/>
                  </div>
                </div>
              </Link>
              <Link to="/projects/shevchenko" style={{width: 'calc((100vw - 200px) / 3)'}}>
                <div
                  className="project-card"
                  onMouseOver={() => (setThirdArrowImage(arrowWhite))}
                  onMouseOut={() => (setThirdArrowImage(arrow))}
                >
                  <img className="project-image" alt="shevchenko" src={shevchenko} width={'100%'} height={255}
                       style={{borderRadius: '36px'}}/>
                  <p className="card-title">{t('ourProjectsBlock.shevchenkoShort')}</p>
                  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <p className="card-performance">{t('ourProjectsBlock.shevchenkoPerformance')}</p>
                    <img className="project-image" alt="arrow" src={thirdArrowImage} width={40} height={20}/>
                  </div>
                </div>
              </Link>
            </Row>
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}