import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Carousel, Typography} from "antd";
import {Link} from "react-router-dom";
import slide1 from "../../utils/images/treats/base-slide1.png";
import slide2 from "../../utils/images/treats/base-slide2.png";
import slide3 from "../../utils/images/treats/base-slide3.png";
import slide4 from "../../utils/images/treats/base-slide4.png";
import slide5 from "../../utils/images/treats/base-slide5.png";
import leftArrow from "../../utils/images/leftArrow.png";
import rightArrow from "../../utils/images/rightArrow.png";


export default function ManufactureBase() {
  const {t} = useTranslation();
  const {Text} = Typography;

  const bannerRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const onChange = (current) => {
    setCurrentSlide(current);
  };

  const slideList = [slide1, slide2, slide3, slide4, slide5];

  return (
    <div className="treatment-page">
      <div className="manufacture-block">
        <div style={{margin: window.innerWidth < 1015 ? "0 24px" : "0 80px"}}>
          <Text className="navigation navigation-treat mb-3">
            <Link className="nav-text" to="/" style={{textDecoration: 'none', color: ' #0E2C58'}}>
              {t('mainPage')}
            </Link>
            /<b>{t('treatmentFacilities')}</b>
          </Text>
          <h1 className="title-32 mb-3 font-euclid-flex"
              style={{marginTop: window.innerWidth < 1015 ? "16px" : "20px"}}>{t('treatment.title')}</h1>
          {window.innerWidth < 1015 ? (
            <div>
              <p className="treat-desc">{t('treatment.descriptionMobile')}</p>
              <p className="treat-desc">{t('treatment.descriptionMobile2')}</p>
            </div>
          ) : (<Text className="treat-desc">{t('treatment.description')}</Text>)}
        </div>
        <div className="our-manufacture">
          <Carousel
            afterChange={onChange}
            ref={ref => {
              bannerRef.current = ref
            }}
            dots={false}
          >
            {slideList.map((item, index) => (
              <div key={index}>
                <img
                  style={{margin: "0 auto", width: '100vw'}}
                  src={item}
                  alt={`slide + ${index}`}
                  height={window.innerWidth < 1015 ? 236 : 800}
                />
              </div>
            ))}
          </Carousel>
          <div className="left-arrow" onClick={() => bannerRef.current.prev()}>
            <img className="left-arrow-icon" src={leftArrow} alt="left"/>
          </div>
          <div className="right-arrow" onClick={() => bannerRef.current.next()}>
            <img className="right-arrow-icon" src={rightArrow} alt="right"/>
          </div>
          <div className="slider-dots-buttons">
            <div
              className={`${currentSlide === 0 && 'active-dot'} dots-buttons`}
              onClick={() => bannerRef.current.goTo(0)}
            ></div>
            <div
              className={`${currentSlide === 1 && 'active-dot'} dots-buttons`}
              onClick={() => bannerRef.current.goTo(1)}
            ></div>
            <div
              className={`${currentSlide === 2 && 'active-dot'} dots-buttons`}
              onClick={() => bannerRef.current.goTo(2)}
            ></div>
            <div
              className={`${currentSlide === 3 && 'active-dot'} dots-buttons`}
              onClick={() => bannerRef.current.goTo(3)}
            ></div>
            <div
              className={`${currentSlide === 4 && 'active-dot'} dots-buttons`}
              onClick={() => bannerRef.current.goTo(4)}
            ></div>
          </div>
          <p className="font-euclid-flex p-18 mb-3">{t('treatment.title')}</p>
        </div>
      </div>
    </div>
  );
}