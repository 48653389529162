import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Typography, Row, Image} from "antd";
import parse from 'html-react-parser';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import one from "../../utils/images/projects/shevchenko/1.png";
import two from "../../utils/images/projects/shevchenko/2.png";
import three from "../../utils/images/projects/shevchenko/3.jpg";
import four from "../../utils/images/projects/shevchenko/4.jpg";
import five from "../../utils/images/projects/shevchenko/5.jpg";
import six from "../../utils/images/projects/shevchenko/6.jpg";
import seven from "../../utils/images/projects/shevchenko/7.jpg";
import eight from "../../utils/images/projects/shevchenko/8.jpg";
import nine from "../../utils/images/projects/shevchenko/9.jpg";
import './shevchenko.css';
import {Link} from "react-router-dom";
import HeaderMobileMenu from "../../components/Header/HeaderMobileMenu";

export default function Shevchenko() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {t} = useTranslation();
  const {Text} = Typography;

  const row1 = [one, two, three];
  const row2 = [four, five, six];
  const row3 = [seven, eight, nine];


  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onValueChanged = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  const isMobile = window.innerWidth < 1015;

  return (
    <div style={{position: "relative", width: "100vw", minHeight: "100vh"}}>
      <Header buttonClick={onValueChanged} isOpenMenu={isOpenMenu}/>
      {isOpenMenu ? (
        <HeaderMobileMenu/>
      ) : (
        <>
          <div className="shevchenko-page">
            <div className="texts">
              <Text className="navigation navigation-shevchenko mb-3">
                <Link className="nav-text" to="/" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                  {t('mainPage')}
                </Link>/
                <Link className="nav-text" to="/projects" style={{textDecoration: 'none', color: ' #0E2C58'}}>
                  {t('ourProjects')}
                </Link>
                /<b>{t('ourProjectsBlock.shevchenko')}</b>
              </Text>
              <h1 className="title-32 mb-3 font-euclid-flex">{t('ourProjectsBlock.shevchenko')}</h1>
            </div>
            <div className="texts">
              {isMobile ? (<>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.shevchenkoDescMobile'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.shevchenkoDescMobile2'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.shevchenkoDescMobile3'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.shevchenkoDescMobile4'))}
                  </p>
                  <p className="desc-text">
                    {parse(t('ourProjectsBlock.shevchenkoDescMobile5'))}
                  </p>
                </>
              ) : (
                <p className="desc-text">
                  {parse(t('ourProjectsBlock.shevchenkoDesc'))}
                </p>
              )}
              <h5 className="desc-text">
                <b>{isMobile ? t('ourProjectsBlock.shevchenkoJobMobile') : t('ourProjectsBlock.shevchenkoJob')}</b>
              </h5>
              {!isMobile && (
                <>
                  <Row className="projects-row">
                    {row1.map((item, index) => (
                      <div className="row-images" key={index}>
                        <Image
                          src={item} alt={`row1 + ${index}`}
                          height={237}
                          style={{borderRadius: '32px'}}
                          width={'100%'}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="projects-row" style={{margin: '20px 0'}}>
                    {row2.map((item, index) => (
                      <div className="row-images" key={index}>
                        <Image
                          src={item} alt={`row2 + ${index}`}
                          height={237}
                          width={'100%'}
                          style={{borderRadius: '32px'}}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="projects-row" style={{margin: '20px 0'}}>
                    {row3.map((item, index) => (
                      <div className="row-images" key={index}>
                        <Image
                          src={item}
                          alt={`row2 + ${index}`}
                          height={237}
                          width={'100%'}
                          style={{borderRadius: '32px'}}
                        />
                      </div>
                    ))}
                  </Row>
                </>
              )}
            </div>
            {isMobile && (
              <>
                <Row className="projects-row">
                  {row1.map((item, index) => (
                    <div className="row-images" key={index}>
                      <Image src={item} alt={`row1 + ${index}`} height={215}
                             width={'100%'}/>
                    </div>
                  ))}
                </Row>
                <Row className="projects-row" style={{margin: '20px 0'}}>
                  {row2.map((item, index) => (
                    <div className="row-images" key={index}>
                      <Image src={item} alt={`row2 + ${index}`} height={215} width={'100%'}/>
                    </div>
                  ))}
                </Row>
                <Row className="projects-row" style={{margin: '20px 0'}}>
                  {row3.map((item, index) => (
                    <div className="row-images" key={index}>
                      <Image src={item} alt={`row2 + ${index}`} height={215} width={'100%'}/>
                    </div>
                  ))}
                </Row>
              </>
            )}
          </div>
          <Footer/>
        </>
      )}
    </div>
  );
}